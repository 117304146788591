.content-container {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 20px;
  gap: 50px; 
 
}

.chatbot {
  width: auto; /* Permite que el contenedor se ajuste al tamaño del iframe */
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-left: 150px;
  padding: 20px; /* Ajusta el relleno si es necesario */
    flex: 0 1 auto; /* No permite que los contenedores crezcan, solo usan el espacio necesario */
}

.feedback {
  width: auto; /* Ajusta esto si necesitas un ancho específico */
  padding: 30px; /* Más padding para hacer el recuadro más grande */
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 200px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 27px; /* Aumenta el tamaño de texto general dentro del feedback */
  font-family: Roboto, sans-serif;
}

.upper-container {
  width: 100%;
  background-color: rgb(12, 81, 93); /* Color verde oscuro para el fondo */
  padding: 15px 0; /* Agrega un poco de padding vertical */
  display: flex;
  justify-content: center; /* Centra el contenido horizontalmente */
  align-items: center; /* Centra el contenido verticalmente */
}

#titulo1 {
  width: 100%;
  text-align: center;
  color: white;
  background-color: rgb(12, 81, 93);
  font-family: Roboto, sans-serif;
  font-size: 25px;
}

#pregunta1{
  font-family: Roboto, sans-serif;
}

#gracias{
  font-family: Roboto, sans-serif;
}


#pregunta2{
  font-family: Roboto, sans-serif;
}

#enviar{
  display: inline-block;
  border: none;
  background-color: rgb(12, 81, 93);
  color: white;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  padding: 10px 15px;
  margin: 6px;
  border-radius: 4px;
}

.star-rating button {
  font-size: 50px; /* Hace las estrellas más grandes */
  color: gray;
  cursor: pointer;
  background: none;
  border: none;
}

.star-rating .on {
  color: gold;
}

.star-rating .off {
  color: gray;
}

.comment-container {
  display: flex; /* Activa Flexbox */
  align-items: center; /* Alinea verticalmente los elementos al centro */
  gap: 10px;
}

.comment-input {
  width: 100%; /* Ajusta el ancho al 100% del contenedor padre */
  padding: 10px 15px; /* Añade padding para hacer el input más alto y cómodo para escribir */
  font-size: 16px; /* Aumenta el tamaño de la fuente para mejor legibilidad */
  border: 2px solid #ccc; /* Añade un borde sólido */
  border-radius: 5px; /* Suaviza los bordes para hacerlos ligeramente redondeados */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Opcional: añade una sombra ligera para profundidad */
}

.comment-input:focus {
  border-color: #007BFF; /* Cambia el color del borde al enfocarse para mejor visibilidad */
  outline: none; /* Elimina el outline predeterminado para un estilo más limpio */
}

.user-icon img:hover {
  cursor: pointer;
  opacity: 0.9; /* Ligeramente transparente al pasar el mouse */
}

.user-icon img {
  width: 55px;  /* Tamaño más pequeño, ajusta según tus necesidades */
  height: 55px; /* Mismo valor que el ancho para mantener la proporción y hacerla circular */
  border-radius: 50%; /* Hace que los bordes de la imagen sean completamente redondos */
  object-fit: cover;
  margin-right: 10px; /* Asegura que la imagen cubra completamente el espacio sin distorsionarse */
}

.dropdown-menu {
  position: absolute;
  right: 0; /* Alinea el menú con el lado derecho del icono de usuario */
  top: 60px; /* Posición justo debajo del icono de usuario */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.25);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px; /* O el ancho que prefieras */
  margin: 0;
  margin-top: 20px;
}

.menu-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-user-image {
  width: 60px; /* Tamaño más grande para la imagen dentro del menú */
  height: 60px;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 2px solid #ccc; /* Borde gris para la imagen */
}

.dropdown-menu a {
  text-decoration: none;
  color: black;
  padding: 5px 10px;
  text-align: center;
}

.dropdown-menu a:hover {
  background-color: #f0f0f0;
}
